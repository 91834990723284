import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import BrandSection from "../../components/brandSection";
import ContactSection from "../../components/contactSection";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import ServiceCards from "../../components/serviceCards";
import TestimonialCards from "../../components/testimonialCards";
import ProjectCards from "../../components/projectCards";
import './style.scss';

const Consulting = () => {
    return (
        <>
            <Helmet>
                <title>Digitalbuddha </title>
            </Helmet>
            <NavBar />
            <div className="consulting">
                <Hero />
                <Services />
                <About />
                <Projects />
                <Stats />
                <Testimonials />
                <Contact />
                <Footer />
            </div>
        </>
    );
};


const Hero = () => {
    const counters = [
        {
            count: '6+',
            text: "Years"
        },
        {
            count: '25+',
            text: "Employees"
        },
        {
            count: '15+',
            text: "Countries"
        }
    ];


    const brands = [
        {
            name: "brand1",
            imgUrl: "assets/b1.svg"
        },
        {
            name: "brand2",
            imgUrl: "assets/b2.svg"
        },
        {
            name: "brand3",
            imgUrl: "assets/b3.svg"
        },
        {
            name: "brand4",
            imgUrl: "assets/b4.svg"
        },
        {
            name: "brand5",
            imgUrl: "assets/b5.svg"
        }
    ];

    return (
        <>
            <section className=" container section-global no-border">
                <div className="container row gy-5" style={{ justifyContent: "space-between", paddingTop: "40px" }}>
                    {/* <div className="row gy-5 "> */}
                    <div id="p" className="col-lg-6 align-self-center container">
                        <h1 className="H1">Your trusted partner for innovative <b className="it"> IT </b>  solutions</h1>

                        <p id="paragraph1" className='txt-1 mt-16 me-lg-5'>We are a full-service IT partner agency, offering a wide range of solutions and support to businesses of all sizes. Our team is dedicated to helping you succeed.</p>
                        <form id="project-buttn" className='mt-20 me-lg-5'>

                            {/* <Link to='/contact' id="getintouch" className='btn btn-primary btn-lg'>Get in touch</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            <div className="buttn-style">

                                <div> <a id="getintouch" class="btn btn-primary btn-lg " href="/contact">Get in touch</a></div>
                                <div>  <a id="project-btn" class="btn btn-primary btn-lg" href="/projects">Our projects</a></div>


</div>
                            {/* <Link to='/projects' id="project-btn" className='btn btn-primary btn-lg'>Our projects</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}

                            <div className="counter mt-20 d-flex " id='countnew'>
                                {
                                    counters.map((e, i) =>
                                        <div className="count txt-center" key={i}>
                                            <h2 className='mb-0'>{e.count}</h2>
                                            <div className='txt-2'>{e.text}</div>
                                        </div>
                                    )}
                            </div>
                        </form>
                    </div>
                    {/* <div className="col-6 "> */}
                    {/* <img className="homeimg" src="file:///C:/Users/Aiswarya%20Pramod/Downloads/248X400%203D%20charector-01.webp" alt=""/> */}
                    <img id="home2" src={require('./assets/p1.png')} className="img-fluid" alt="hero" />

                    {/* </div> */}
                    {/* </div> */}
                </div>
            </section>
            <BrandSection brands={brands} src={'consulting'} bordered />
        </>
    );
};

const Services = () => {
    const services = [
        {
            name: 'Digital Marketing',
            info: 'We are dedicated to delivering measurable results for our clients with the latest digital marketing trends and techniques.',
            link: '/services',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Web&App Development',
            info: 'We specialize in creating intuitive, user-friendly, and visually-appealing websites and mobile apps.',
            link: '/services',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Branding',
            info: 'We offer a range of services, including brand strategy, identity design, packaging design, and digital design.',
            link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Project Management',
            info: 'Our project managers is skilled in the latest tools and techniques for planning, executing, and delivering successful projects.',
            link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        }
    ];
    return (
        <>
            <section className="section-global">
                <div className="container">
                    <div className="row mb-40">
                        <div className=" ">
                            <div id="service-clr" className="section-tag mb-8">SERVICES</div>
                            <h2 className="H2">Unleashing the power of technology
                                <br></br>for your business success</h2>

                        </div>

                    </div>
                    <div id="paragraph" className="row">
                        {
                            services.map((service, i) =>
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4" key={i}>
                                    <ServiceCards data={service} src="consulting" />
                                </div>
                            )
                        }
                    </div>
                    <div className=" mob_service_btn col d-flex align-self-center">
                        <Link to='/services' className='btn btn-outline btn-arrow ms-lg-auto'>
                            See all services
                            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black" />
                            </svg>
                        </Link>
                    </div>
                </div>

            </section>

        </>
    );
};

const About = () => {

    const features_data = [
        "Seamless, comprehensive, and cost-effective",
        "Connect with your customers",
        "Streamline your operations"
    ];

    return (
        <>
            <section id="clr1" className="section-global bg-shade-1">
                <div className="container">
                    <div className="row mb-40 gy-4">
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{ maxWidth: '1000px' }}>
                                {/* <img className="aboutimg" src="https://image.freepik.com/free-photo/business-team-two-managers-working-accounting-documents-team-work-together_49553-108.jpg"></img> */}
                                <img id="imgstyle1" src={require('./assets/3d1.png')} className="img-fluid" alt="About" />
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div id="color-portfolio" className="section-tag mb-8">WHY DIGITALBUDDHA</div>
                            <h2 className="H2">Every skillset you need to grow at your fingertips</h2>
                            <br />
                            <p id="paragraph" className='txt-1'>
                                We are a dynamic team of designers, developers, consultants, analysts, marketing experts, artists, content writers who love to solve problems and create values. Our team includes the top talents from every skills you need, We become your extended marketing department and single point of contact for every skills. We are always transparent and accountable. At digitalbuddha we pride ourselves in quality, speed, affordability and communication while engaging with our clients on design and tech projects.                            </p>
                            <div className='mt-16'>
                                <li id="paragraph" >Results driven</li>
                                <li id="paragraph" >Quality promised</li>
                                <li id="paragraph" >Cost effective</li>
                            </div>
                        </div>
                    </div>
                    <div className="col d-flex align-self-center">
                        <Link to='/projects' className='btn btn-outline btn-arrow ms-lg-auto'>
                            See all projects
                            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black" />
                            </svg>
                        </Link>
                    </div>
                    <div className="row pt-5 gy-4">
                        <div className="col-lg-6 align-self-center">
                            <div id="color-portfolio" className="section-tag mb-8">WHY TO CHOOSE US?</div>
                            <h2 className='mb-16'></h2>
                            <h2 className="H2">Innovative technology solutions for a smarter future</h2>
                            <br />
                            <h4>Cutting Edge Technology</h4>
                            <p id="paragraph1"  >We ensure that our clients receive the most effective and up-to-date solutions available</p>
                            <h4>Fair and Transparent Pricing</h4>
                            <p id="paragraph1" >We offer flexible pricing options to suit the unique needs and budgets of our clients</p>
                            <h4>Delivering High-quality</h4>
                            <p id="paragraph1"  >We have a proven track record of success and a strong reputation in delivering high quality projects</p>
                            {/* {features_data.map((e,i)=> <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e}
                            </div>)} */}
                            {/* <div className='mt-16'> */}
                            {/* <Link to="/about" className='arrow-link'>
                                    Learn more
                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-1'>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="currentColor"/>
                                    </svg>
                                </Link> */}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div >
                                <img id="pic3" src={require('./assets/3d2.png')} className="img-fluid" alt="About" />

                                {/* <img  src={require('./assets/pic1.png')} className="img-fluid" alt="About"/>                                 */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

//////////////////////////////////////////

const Projects = () => {

    const projects_data = [
        {
            category: 'Slaw ',
            // text: "Teno's journey from small to giant",
            // imgUrl: 'assets/project3.png',
            // svg: 'assets/wreath.svg',
            imgUrl: "assets/project1.jpg",
            color: "#890d49",
            link: '/slaw'
        },
        {
            category: 'CR Hope',
            // text: 'LAPI - Single entry point API management',
            // imgUrl: 'assets/project1.png',

            imgUrl: "assets/project2.jpg",
            // json:'assets/Web Cover Animation.json',
            // svg: 'assets/wreath.svg',
            color: "#F97316",
            link: '/crhope'

        },
        {
            category: 'Euclid',
            // text: 'How Deko manages to scale using Stact',
            // imgUrl: 'assets/project2.png',
            // svg: 'assets/wreath.svg',

            imgUrl: "assets/project3.jpg",
            color: "#2D7EF8",
            link: '/euclid'
        }

    ];

    return (
        <>
            <section className='section-global'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div id='color-portfolio' className="section-tag mb-8">PORTFOLIO</div>
                            <h2 className="H2" >Our recently completed projects</h2>
                        </div>

                    </div>
                    <div className="row">
                        <ProjectCards data={projects_data} src="consulting" />
                    </div>
                    <div className="col d-flex align-self-center">
                        <Link to='/projects' className='btn btn-outline btn-arrow ms-lg-auto'>
                            See all Projects
                            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};








// //////////////////////////////////////
// const Partners = () => {
//     return(
//         <>
//             <section className="section-global">
//                 <div style={{height:"50px",paddingLeft:"200px",textAlign:"center"}} className="container1">
//                     <div>
//                         <h4>We are now partnered 
//                             <br></br>with</h4>
//                         </div>
//                         <div>
//                         <img className="partner1" src="https://phpstack-601488-3005830.cloudwaysapps.com/static/media/Godaddy.08864a774a9c7d14d42c.svg" alt="" />
//                         </div>
//                         <div>
//                         <img className="partner2" src="https://d2m6ke2px6quvq.cloudfront.net/uploads/2020/09/10/4654ae3f-db49-497a-892e-d8caba77ffd9.png" alt="" />
//                         </div>
//                         <div>
//                         <img className="partner3" src="https://www.edi2xml.com/wp-content/uploads/2020/03/shopify_logo_black.png" alt="" />
//                         </div>

//                 </div>
//             </section>
//         </>
//     )
// }


const Stats = () => {

    const stats_data = [
        {
            head: '4.5 Stars',
            text: 'Overall ratings',
            icoUrl: 'assets/Godaddy.svg'
        },
        {
            head: '554.5k Ratings',
            text: 'On all the platforms',
            icoUrl: 'assets/google cloud.svg'
        },
        {
            head: '5M+ Downloads',
            text: 'Across all platform',
            icoUrl: 'assets/Shopify.svg'
        }
    ];

    return (
        <>
            <section className="section-global">
                <div className="container container-2">
                    <div id="partner" className="row gy-5">
                        <div className="col-lg-3 col-md-12">
                            <h4 className="H2">We are now partnered with</h4>
                        </div>
                        {stats_data.map((e, i) =>
                            <div className="col-lg-3 col-md-4 col-sm-6 text-center" key={i}>
                                <img src={require(`./${e.icoUrl}`)} className="mb-8" height="50" alt="stats" />
                                {/* <h4 className='mb-0'>{e.head}</h4> */}
                                {/* <div className="txt-4">{e.text}</div> */}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

////////////////////////////////////////

const Testimonials = () => {

    const testimonial_data = [
        {
            brandImgUrl: 'assets/ND.png',
            text: 'I highly recommend DigitalBuddha as a valuable partner for any business. Their dedication to customer service, expertise in digital marketing and technical proficiency are unparalleled. Our partnership has been a great pleasure and has resulted in successful projects that we have never undertaken before. Their ability to overcome obstacles and implement effective, timely solutions has been instrumental in our success.',
            userName: 'Daniel Johnson',
            position: 'National Dispatching - Enterprise Solutions Manager',
            userImgUrl: 'assets/review-new.png',
            place: 'USA'
        },
        {
            brandImgUrl: 'assets/review2.svg',
            text: 'Working with the Digitalbuddha team has been a most pleasant experience! Their dedication and passion for what they do is exemplified by their unrivaled customer support and attentiveness to the specific needs of our organisation. We look forward to a long lasting and prosperous relationship with the crew at Digitalbuddha.',
            userName: 'Renos Fountoulakis',
            position: 'Founder | CR Hope',
            userImgUrl: 'assets/customer1.jpg',
            place: 'Dubai'
        },
        {
            brandImgUrl: 'assets/review3.png',
            text: 'Our metal scrap trading division has seen a phenomenal increase in revenue, thanks to SEO reforms done by Digitalbuddha for our website. Queries from our website have increased and have made a great impact on our business. We are very happy with their dedication and efforts',
            userName: 'R.K. Kunhammed',
            position: 'Managing Director | Al Habari Group, Qatar',
            userImgUrl: 'assets/customer3.jpg',
            place: 'Qatar'
        }
    ];

    return (
        <>

            <div id="testimonial-clr" className="section-global bg-shade-white">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div id="service-clr" className="section-tag mb-8">CUSTOMER REVIEWS</div>
                            <h2 className="H2">Adopted by the most creative individuals</h2>
                        </div>

                    </div>
                    <div id="paragraph" className="row">
                        <TestimonialCards data={testimonial_data} src="consulting" />
                    </div>
                </div>
            </div>
        </>


    );
};

const Contact = () => {

    const contact_data = {
        title: 'Have an idea?',
        title_highlight: "Let's talk",
        text: "One of the best industry service providers with top tier talented individuals.",
        link: '/contact'
    };

    return (
        <>
            <ContactSection contact_data={contact_data} />
        </>
    );
};


export default Consulting;