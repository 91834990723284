import Helmet from 'react-helmet'
// import FeatureCards from '../../components/featureCards'
import Footer from '../../components/footer'
import './style.scss'
import NavBar from "../../components/navbar"
import ContactSection from '../../components/contactSection'

const Aboutpage = () => {
    return(
        <>
            <Helmet>
                <title>Digitalbuddha</title>
            </Helmet>
            <NavBar/>
            <div className="about-container">
                <Hero />
                <About />
                {/* <AboutSection /> */}
                <Counters />
                {/* <Features /> */}
                {/* <Team /> */}
                <Contact />
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>
            <section id='mainsection' className='section-global bg-shade-2 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div id="color"  className="section-tag mb-8">About Us</div>
                            <h1 id='color1' className='display-1'>Elevate your business with our digital solutions</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const About = () => {

    const features_data = [
        "Results driven",
        "Quality promised",
        "Cost effective"
    ]

    return(
        <>  
            <section id="section" className="section-global bg-shade-1">
                <div className="container">
                    <div className="row mb-40 gy-4">
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{maxWidth: '1000px'}}>
                                {/* <img className="aboutimg" src="https://image.freepik.com/free-photo/business-team-two-managers-working-accounting-documents-team-work-together_49553-108.jpg"></img> */}
                                <img id="imgstyle1" src={require('./assets/3d4.png')} className="img-fluid" alt="About"/>                                
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div id="color-portfolio"  className="section-tag mb-8">WHY DIGITALBUDDHA</div>
                            <h2  className="H2">Every skillset you need to grow at your fingertips</h2>
                            <br />
                            <p  id="paragraph1"   className='txt-1'>
                            We are a dynamic team of designers, developers, consultants, analysts, marketing experts, artists, content writers who love to solve problems and create values. Our team includes the top talents from every skills you need, We become your extended marketing department and single point of contact for every skills. We are always transparent and accountable. At digitalbuddha we pride ourselves in quality, speed, affordability and communication while engaging with our clients on design and tech projects.                            </p>
                            <div className='mt-16'>
                              <li  id="paragraph" >Results driven</li>
                              <li  id="paragraph" >Quality promised</li>
                              <li  id="paragraph" >Cost effective</li>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5 gy-4">
                        <div className="col-lg-6 align-self-center">
                            <div id="color-portfolio"  className="section-tag mb-8">WHY TO CHOOSE US?</div>
                            <h2 className='mb-16'></h2>
                            <h2 className="H2">Innovative technology solutions for a smarter future</h2>
                            <br />
                            <h4>Cutting Edge Technology</h4>
                            <p  id="paragraph1"  >We ensure that our clients receive the most effective and up-to-date solutions available</p>
                            <h4>Fair and Transparent Pricing</h4>
                            <p  id="paragraph1"  >We offer flexible pricing options to suit the unique needs and budgets of our clients</p>
                            <h4>Delivering High-quality</h4>
                            <p id="paragraph1"  >We have a proven track record of success and a strong reputation in delivering high quality projects</p>
                            {/* {features_data.map((e,i)=> <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e}
                            </div>)} */}
                            {/* <div className='mt-16'> */}
                                {/* <Link to="/about" className='arrow-link'>
                                    Learn more
                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-1'>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="currentColor"/>
                                    </svg>
                                </Link> */}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{maxWidth: '500px'}}>
                            <img id="pic3" src={require('./assets/3d3.png')} className="img-fluid" alt="About"/>                                

                                {/* <img  id="img2" src="https://ufv.ca/media/assets/programs/facebook/computer-skills-facebook.jpg" className="img-fluid" alt="About"/>                                 */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Counters = () => {
    const counter_data = [
        {
            count: '6+',
            text: 'Years'
        },
        {
            count: '25+',
            text: 'Employees'
        },
        {
            count: '15+',
            text: 'Countries'
        },
        {
            count: '150+',
            text: 'Clients'
        }
    ]
    return(
        <>
            <section  className=' about_section counter-section dark'>
                <div className="container">
                    <div className="row">
                        {counter_data.map((e,i) => 
                            <div className="col-lg-3 col-md-4 col-6 gy-7 " key={i}>
                                <h2 className="display-1">
                                    {e.count}
                                </h2>
                                <p className='txt-1'>{e.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

// const Features = () => {

//     const featurs_data = [
//         {
//             title: 'Research',
//             text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
//         },
//         {
//             title: 'Build',
//             text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
//         },
//         {
//             title: 'Scale',
//             text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
//         }
//     ]

//     return(
//         <>
//             <section className='section-global'>
//                 <div className="container">
//                     <div className="row mb-40">
//                         <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
//                             <div className="section-tag mb-8">How we work</div>
//                             <h2>Extremely smooth workflow with cosistancy</h2>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="container container-2">
//                     <div className="row gy-4 gx-0 gx-md-5">
//                         <FeatureCards data={featurs_data} src='about'/>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

// const Team = () => {
//     const team_data = [
//         {
//             name: 'Sarah Winnemucca',
//             position: 'Founder & CEO',
//             imgUrl: 'assets/team1.jpg'
//         },
//         {
//             name: 'Alice Paul',
//             position: 'Founder & CTO',
//             imgUrl: 'assets/team2.jpg'
//         },
//         {
//             name: 'Margaret Sanger',
//             position: 'Chief Revenue Officer',
//             imgUrl: 'assets/team3.jpg'
//         },
//         {
//             name: 'Katharine Lee',
//             position: 'Chief Marketing Officer',
//             imgUrl: 'assets/team4.jpg'
//         }
//     ]
//     return(
//         <>
//             <section className='section-global bg-shade-blue'>
//                 <div className="container container-2">
//                     <div className="row mb-40">
//                         <div className="col-lg-8">
//                             <div className="section-tag mb-8">Team</div>
//                             <h2 className='mb-16'>A team of skilled idividuals that helps you to grow</h2>
//                         </div>
//                     </div>
//                     <div className="row gy-4">
//                         {team_data.map((e,i)=>
//                             <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
//                                 <div className="team-member text-center">
//                                     <img src={require(`./${e.imgUrl}`)} className="img-fluid mb-16" alt={e.name} />
//                                     <div className="txt-2 color-1 fw-500">{e.name}</div>
//                                     <div className="txt-3">{e.position}</div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
        <div id='mainsection'>
            <ContactSection  contact_data={contact_data}/>
            </div>
        </>
    )
}

export default Aboutpage