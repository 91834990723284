import './style.scss'
import React, { useRef } from "react";
import { useState } from 'react';
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';



        const ContactSection = () => {
            const form = useRef();
            
                const sendEmail = (e) => {
                  e.preventDefault();
              
                  emailjs
                    .sendForm(
                      "service_fffs5xo",
                      "template_g9nkvno",
                      form.current,
                      "WtWnzI12f9avbq-rK"
                    )
                    .then(() => {
                        Swal.fire({
                            icon:'success',
                            title:'Submitted successfully',
                            text:'Thank you for contacting us!'
                        });
            
                      
                      })
                      .catch((error)=>{
                        console.log(error);
                      })
                };
            

        // const [status, setStatus] = useState("Submit");
        // const handleOnSubmit = async (e) => {
        //   e.preventDefault();
        //   const firstname = e.target.user_firstname.value 
        //   const lastname = e.target.user_lastname.value 

        //   const email = e.target.user_email.value
        //   const message = e.target.message.value
        //   const subject = e.target.subject.value
        //   let details = {
        //     firstname,
        //     lastname,
        //     email,
        //     message,
        //     subject,
        //   };
        //   let response = await fetch("https://api.elasticemail.com/v2/email/send", {
        //     method: "POST",
           
        //     body:JSON.stringify({
        //         apikey: 'EAA9E724A1F85B2E113B2A2B12D6E89F4CBFEF4E28F17B4631F3DF459D93C1F833D6E72A047B075B90037ABE6DBE11D2',
        //         subject: details.subject,
        //         from: details.email,
        //         to: 'jyothismanoj000@gmail.com',
        //         bodyText: details.message
        //     }),

           
            
           
            
        //   });
        //   setStatus("Submit");
        //   let result = await response.json();
        //               Swal.fire({
        //         icon:'success',
        //         title:'Submitted successfully',
        //         text:'Thank you for contacting us!'
        //     });

        // };
            return(
            <>  
<section  className='section-global'>
                <div className="container container-2">
                    <div className="row gy-5">
                        <div className="contact_main_img1 col-lg-6">
                        <h2 id='color-portfolio' >Get in touch</h2>

                            <div className="contact_main_image" >
                            <img src={require('./assets/c2.png')} className="img-fluid info-banner-image "style={{height:'325px',width:"390px",borderRadius:"15px",paddingBottom:"50px"}} alt="Why stact" />  
                             </div>
                        </div>
                        <div className=" contact_main_img2 col-lg-6">
                            <form id='color-portfolio' className="row g-3" action="https://formspree.io/f/xnqyqvrn" method="POST" ref={form} onSubmit={sendEmail} >
                                <div  className="col-md-6">
                                    <label className="form-label" >First name</label>
                                    <input  id='placeholder-color'  type="text" name='user_fname' className="form-control" placeholder="First name" required />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" >Last name</label>
                                    <input id='placeholder-color' type="text" name='user_lname' className="form-control" placeholder="Last name" />
                                </div>
                                <div className="col-12">

                                    <label className="form-label" >Email</label>
                                    <input id='placeholder-color' type="email"  name='user_email' className="form-control" placeholder="Example@gmail.com" required />
                                </div>
                                <div className="col-12">
                                    <label className="form-label" >Subject</label>
                                    <input id='placeholder-color' type="text" name='subject' className="form-control" placeholder="Enter subject" required/>
                                </div>
                                <div className="col-md-12">
                                    <label  className="form-label">Message</label>
                                    <textarea id='placeholder-color' className="form-control"  name='message' rows="4" placeholder="How can we help you?" required></textarea>
                                </div>
                                <div  id='btn' className=" bttn col-12">
                                    <button id='buttn' type="submit" className="btn btn-primary btn-lg">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </section>
            </>
        )
    }


export default ContactSection