import Helmet from "react-helmet"
import ContactSection from "../../components/contactSection"
import FeatureCards from '../../components/featureCards'
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import ServiceCards from '../../components/serviceCards'
import './style.scss'

const Services = () => {
    return(
        <>
            <Helmet>
                <title>Digitalbuddha</title>
            </Helmet>
            <NavBar/>
            <div className="services-container">
                <Hero/>
                <ServicesSection/>
                {/* <Features/> */}
                <Contact/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section id="mainservice" className='section-global bg-shade-4 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div id="color" className="section-tag mb-8">Services</div>
                            <h1 id="color1" className='display-1'>End-to-end business transformation:Our expertise,your success</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const ServicesSection = () => {

    const services = [
        {
            name: 'Digital Marketing',
            info: 'We are dedicated to delivering measurable results for our clients with the latest digital marketing trends and techniques.',            
            icoUrl: 'assets/icon1.svg'
        },
        {
            name: 'Web&App Development',
            info:'We specialize in creating intuitive, user-friendly, and visually-appealing websites and mobile apps.',
            icoUrl: 'assets/icon2.svg'
        },
        {
            name: 'Branding',
            info: 'We offer a range of services, including brand strategy, identity design, packaging design, and digital design.',
            icoUrl: 'assets/icon3.svg'
        },
        {
            name:'Project Management',
            info: 'Our project managers is skilled in the latest tools and techniques for planning, executing, and delivering successful projects.',
            icoUrl: 'assets/icon4.svg'
        },
        {
            name: 'SEO',
            info: 'Our skilled SEO analysts provide marketing solutions tailored to your business requirements and boost your digital visibility.',
            icoUrl: 'assets/icon5.svg'
        },
        {
            name: 'React Development',
            info: 'We specialise in web development using React to cater to a modern and interactive user experience.',
            icoUrl: 'assets/icon6.svg'
        },
        {
            name: 'UI/UX Design ',
            info: 'Our talented designers create products that engage the unique audience for your business by ensuring ease of access and understanding.',
            icoUrl: 'assets/icon7.svg'
        },
        {
            name: 'Wordpress Development',
            info: 'We work to bring your website expectations to reality by using the latest and demand-oriented platforms.',
            icoUrl: 'assets/icon8.svg'
        },
        {
            name: '2D/3D Animation',
            info: 'We design 2D and 3D animation videos that can be used for informative content, product marketing, and more according to your industry requirements.',
            icoUrl: 'assets/3d icon-01.png'
        },
        {
            name: 'Content Development & Marketing',
            info: 'We create innovative and unique content suitable for your business and develop strategies to ensure its reach among the target audience.',
            icoUrl: 'assets/content marketing icon-01.png'
        },      
        {
            name: 'E-commerce Development',
            info: 'Our expert developers work with prominent e-commerce development platforms to build your business an exceptional online presence.',
            icoUrl: 'assets/E-commerce marketing icon.png'
        },
        {
            name: 'Video Development & Marketing',
            info: 'We develop and edit videos to incorporate into your marketing strategies helping your business to appeal to a wider audience.',
            icoUrl: 'assets/video marketing icon-01.png'
        }




    ]

    return(
        <>
            <section id="service" className='section-global'>
                <div  className="container">
                    <div className="row gx-md-5 gy-5">
                        {
                            services.map((service,i) => 
                                <div  id="paragraph"  className="col-lg-4 col-xl-3 col-md-6 col-sm-6"  key={i}>
                                    <ServiceCards  data={service} src="services"/>
                                </div>                    
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

// const Features = () => {

//     const featurs_data = [
//         {
//             title: 'Research',
//             text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
//         },
//         {
//             title: 'Build',
//             text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
//         },
//         {
//             title: 'Scale',
//             text: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.'
//         }
//     ]

//     return(
//         <>
//             <section className='section-global bg-shade-blue'>
//                 <div className="container">
//                     <div className="row mb-40">
//                         <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
//                             <div className="section-tag mb-8">How we work</div>
//                             <h2>Extremely smooth workflow with cosistancy</h2>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="container container-2">
//                     <div className="row gy-4 gx-0 gx-md-5">
//                         <FeatureCards data={featurs_data} src='service'/>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
        <div id="mainservice" >
            <ContactSection contact_data={contact_data}/>
            </div>
        </>
    )
}

export default Services