import Helmet from 'react-helmet';
import Footer from '../../components/footer';
import './style.scss';
import NavBar from "../../components/navbar";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';
import { useState } from 'react';


const Contact = () => {

    return (
        <>
            <Helmet>
                <title>Digitalbuddha</title>
            </Helmet>
            <NavBar />
            <div className="contact-container">
                <Hero />
                <ContactSection />
                <ContactLinks />
                <Footer />
            </div>
        </>
    );
};

const Hero = () => {
    return (
        <>
            <section id='maincontact' className='section-global bg-shade-5 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div id="color" className="section-tag mb-8">Contact</div>
                            <h1 id='color1' className='display-1'>Count on us to be here for you, committed to your satisfaction</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};



//     const [status, setStatus] = useState("Submit");
//     const handleOnSubmit = async (e) => {
//       e.preventDefault();
//       const firstname = e.target.user_firstname.value 
//       const lastname = e.target.user_lastname.value 

//       const email = e.target.user_email.value
//       const message = e.target.message.value
//       const subject = e.target.subject.value
//       let details = {
//         firstname,
//         lastname,
//         email,
//         message,
//         subject,
//       };
//       let response = await fetch("https://api.elasticemail.com/v2/email/send", {
//         method: "POST",

//         body:JSON.stringify({
//             apikey: 'EAA9E724A1F85B2E113B2A2B12D6E89F4CBFEF4E28F17B4631F3DF459D93C1F833D6E72A047B075B90037ABE6DBE11D2',
//             subject: details.subject,
//             from: details.email,
//             to: 'jyothismanoj000@gmail.com',
//             bodyText: details.message
//         }),
//       });
//       setStatus("Submit");
//       let result = await response.json();
//       Swal.fire({
//         icon:'success',
//         title:'Submitted successfully',
//         text:'Thank you for contacting us!'
//     });
// };
const ContactSection = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_fffs5xo",
                "template_g9nkvno",
                form.current,
                "WtWnzI12f9avbq-rK"
            )
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Submitted successfully',
                    text: 'Thank you for contacting us!'
                });


            })
            .catch((error) => {
                console.log(error);
            });
    };




    

    // const features_data = [
    //     "Seamless, comprehensive, and cost-effective",
    //     "Connect with your customers",
    //     "Streamline your operations"
    // ]

    return (
        <>
            <section id='contct' className='section-global'>
                <div className="container container-2">
                    <div className="row gy-5">
                        <div className="col-lg-6">
                            <div className='contactimg'>
                                <img id='contactimage' src={require('./assets/contact5.jpg')} className="img-fluid info-banner-image" alt="Why stact" />
                                {/* <img src={require('./assets/team.jpg')} className="img-fluid info-banner-image" alt="Why stact" />    */}


                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form id='color-portfolio' className="row g-3" action="https://formspree.io/f/xnqyqvrn" method="POST" ref={form} onSubmit={sendEmail}   >
                                <div className="col-md-6">
                                    <label className="form-label" >First name</label>
                                    <input id='placeholder-color' type="text" name='user_fname' className="form-control" placeholder="First name" required />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" >Last name</label>
                                    <input id='placeholder-color' type="text" name='user_lname' className="form-control" placeholder="Last name" />
                                </div>
                                <div className="col-12">

                                    <label className="form-label" >Email</label>
                                    <input id='placeholder-color' type="email" name='user_email' className="form-control" placeholder="Example@gmail.com" required />
                                </div>
                                <div className="col-12">
                                    <label className="form-label" >Subject</label>
                                    <input id='placeholder-color' type="text" name='subject' className="form-control" placeholder="Enter subject" required />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Message</label>
                                    <textarea id='placeholder-color' className="form-control" name='message' rows="4" placeholder="How can we help you?" required></textarea>
                                </div>
                                <div id='btn' className=" bttn col-12">
                                    <button id='buttn' type="submit" className="btn btn-primary btn-lg">Submit</button>


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const ContactLinks = () => {

    const contactLinkData = [
        {
            title: "Email",
            text: "info@digitalbuddha.net",
            icoUrl: "assets/contact_ico1.svg",
            link: "mailto:info@digitalbuddha.net"
        },
        {
            title: "Phone",
            text: "+91 8594 00 10 10",
            icoUrl: "assets/contact_ico2.svg",
            link: "tel:+918594001010"
        },
        {
            title: "Headquarters",
            text: "Downtown mall, Kannur",
            icoUrl: "assets/contact_ico3.svg",
            link: "https://goo.gl/maps/RsGJLL2CXPycZSgm7"
        }
    ];

    return (
        <>
            <section id='maincontact' className='section-global bg-shade'>
                <div className="container">
                    <div className="row gx-md-3 gy-3">
                        {
                            contactLinkData.map((e, i) =>
                                <div className="contactway" key={i}>
                                    <a id="paragraph" href={e.link} className="contact-link">
                                        <div className="contact-link-container d-flex align-items-center">
                                            <img src={require(`./${e.icoUrl}`)} height="50" alt={e.title} />
                                            <div className='ms-3'>
                                                <div className="txt-2 fw-500 color-1">{e.title}</div>
                                                <div className="txt-3">{e.text}</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;