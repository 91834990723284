import Helmet from 'react-helmet'
import ContactSection from "../../components/contactSection"
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import './style.scss'

const Privacy = () => {
    return (
        <>
            <Helmet>
                <title>Digitalbuddha - Landing Page</title>
            </Helmet>
            <NavBar />
            <div className="project-detail-container">
                <Hero />
                <CaseStudy />

                <Contact />
                <Footer />
            </div>
        </>
    )
}

const Hero = () => {
    return (
        <section id="mainservice" className='section-global bg-shade-4 hero'>
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    {/* <div id="color" className="section-tag p-5"></div> */}
                    <h1 id="color1" className='display-1'>Privacy Policy</h1>
                </div>
            </div>
        </div>
    </section>

    )
}

const CaseStudy = () => {
    return (
        <>
            <section className='section-global case-study bg-white '>
                <div className="container container-2">
                   <div className='privacy-policy'>
                    <h2>What information do we collect?</h2>
                    <p>We only collect personally identifiable information from you when you log into our site or enter information into our form fields. You may be asked to enter your: name, e-mail address, mailing address or phone number.</p>
                    <h2>What do we use your information for?</h2>
                    <p>Any of the information we collect from you may be used in one of the following ways:</p>
                    <ul>
                        <li><h3>To personalize your experience</h3></li>
                        <p>(your information helps us to better respond to your individual needs)</p>
                        <li><h3>To improve our website</h3></li>
                        <p>(we continually strive to improve our website offerings based on the information and feedback we receive from you)</p>
                        <li><h3>To improve customer service</h3></li>
                        <p>(your information helps us to more effectively respond to your customer service requests and support needs)</p>
                        <li><h3>To send periodic emails</h3></li>
                        <p>(the email address you provide may be used by automated, trigger-based email campaigns to send you information, respond to inquiries, and/or other requests or questions)</p>
                        <li><h3>To contact you</h3></li>
                        <p>(if you request that we contact you)</p>
                    </ul>
                    <h2>How do we protect your information?</h2>
                    <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. Your information is stored in a password protected database.</p>
                    <h2>Do we use cookies?</h2>
                    <p>Yes, Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. We use cookies to help us remember, understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. This information is collected in an anonymous form that includes no personally identifiable information. No personally identifiable information is collected on this website unless that information is explicitly submitted via a fill-in form. For example, an email address submission is requested in order to Work With Us on https://digitalbuddha.in/ and users will have the option to provide this information or not. This anonymous information generated by cookies is transmitted to Google where it is processed to generate anonymous, aggregate reporting on website activity strictly for the domain https://digitalbuddha.in/. These reports are used to gain insight on how best to optimize the user experience, content, and marketing at https://digitalbuddha.in/.</p>
                    <h2>Remarketing</h2>
                    <p>Cookies are also used to display remarketing advertisements, based upon the user’s previous visits to https://digitalbuddha.in/, across the internet. Remarketing advertisements are displayed through Google’s display-advertisement network and social media. Users may opt out of Google’s use of cookies at any time by visiting the Google Ads Preferences Manager.</p>
                    <h2>Do we disclose any information to outside parties?</h2>
                    <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
                    <h2>Third party links</h2>
                    <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
                    <h2>Online Privacy Policy Only</h2>
                    <p>This online privacy policy applies only to information collected through our website and not to information collected offline.</p>
                    <h2>Terms and Conditions</h2>
                    <p>Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at www.digitalbuddha.in</p>
                    <h2>Your Consent</h2>
                    <p>By using our site, you consent to our websites privacy policy.</p>
                    <h2>Changes to our Privacy Policy</h2>
                    <p>If we decide to change our privacy policy, we will post those changes on this page.</p>
                   </div>
                    
                </div>


            </section>



        </>
    )
}



const Contact = () => {

    const contact_data = {
        title: 'Have an idea?',
        title_highlight: "Let's talk",
        text: "One of the best industry service providers with top tier talented individuals.",
        link: '/contact'
    }

    return (
        <>
            <ContactSection bg="bg-shade-blue" contact_data={contact_data} />
        </>
    )
}


export default Privacy
