import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import NotFound from './pages/404';
import About from './pages/about';
import Consulting from './pages/consulting';
import Contact from './pages/contact';
import FAQs from './pages/FAQs';
import AlHabari from './pages/Habari';
import Slaw from './pages/slaw';
import CRHOPE from './pages/Crhope';
import Acai from './pages/Acai';
import Euclid from './pages/Euclid';
import YKC from './pages/YKC';
import Privacy from './pages/Privacy';
import Almis from './pages/Almis';
import Spacio from './pages/Spacio';
import Suntanker from './pages/Suntanker';
import EthiniqFashion from './pages/Ethiniqfashion';
import Hyrd from './pages/Hyrd';
import CredentOne from './pages/Credentone';
// import Home from './pages/home';
import Projects from './pages/projects';
import Services from './pages/services';
import Products from  './pages/Products';


function Stact() {
  return (
    <>
      <Helmet>
        <title>Stact - React Landing Template</title>    
        <meta
          name="description"
          content="Multipurpose agency and potfolio react template"
        />
      </Helmet>
      <div className="stact-container">
        <Router>
          <ScrollToTop/>
            <Routes>
              {/* <Route path='/' element={<Home />} /> */}
              <Route path='/' element={<Consulting/>} />
              <Route path='/habari' element={<AlHabari/>} />
              <Route path='/slaw' element={<Slaw/>} />
              <Route path='/crhope' element={<CRHOPE/>} />
              <Route path='/ethniqfashion' element={<EthiniqFashion/>} />
              <Route path='/privacy' element={<Privacy/>} />
              <Route path='/acai' element={<Acai/>} />
              <Route path='/almis' element={<Almis/>} />
              <Route path='/spacio' element={<Spacio/>} />
              <Route path='/suntanker' element={<Suntanker/>} />
              <Route path='/hyrd' element={<Hyrd/>} />
              <Route path='/euclid' element={<Euclid/>} />
              <Route path='/ykc' element={<YKC/>} />


              <Route path='/credentone' element={<CredentOne/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/contact' element={<Contact/>} />
              <Route path='/faqs' element={<FAQs/>} />
              <Route path='/projects' element={<Projects/>} />
              <Route path='/products' element={<Products/>} />
              <Route path='/services' element={<Services/>} />
              <Route path='*' element={<NotFound/>} />
            </Routes>
        </Router>
      </div>
    </>
  );
}

const ScrollToTop = () => {
  const {pathname} = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null
}

export default Stact;
