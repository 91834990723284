import { Link } from 'react-router-dom'
import './style.scss'

const ServiceCards = ({data,src}) => {
    return(
        <>
        <div >
            <div className="service">
                <div className="service-icon mb-8">
                    <div className='serviceicon'>
                    <img src={require(`../../pages/${src}/${data.icoUrl}`)} className="mb-8 serviceimg" alt={data.name}/>

                    </div>
                </div>
                <h3>{data.name}</h3>
                {/* <h3>Digital Marketing</h3> */}
                <p className="txt-2 mb-8">{data.info}</p>
            </div>
            </div>
        </>
    )
}

export default ServiceCards