import Helmet from 'react-helmet'
import ContactSection from "../../components/contactSection"
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import { Link } from 'react-router-dom'
import './style.scss'

const Acai = () => {
    return(
        <>
            <Helmet>
                <title>Digitalbuddha</title>
            </Helmet>
            <NavBar/>
            <div className="project-detail-container">
                <Hero/>
                <CaseStudy/>
            
                <Contact/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <h1 id='color-portfolio' className='display-1 mt-5 text-center'>Acai</h1>

        )
}

const CaseStudy = () => {
    return(
        <>
            <section className='section-global case-study'>
                <div className="container container-2">
                <div className='efashion'> 
                <h2 id='display' className='display-2 '>About </h2>
                                <p id="paragraph"  className='txt-1'>A unique venture like Project Acai, a ready-to-eat, acai berry-based food product manufacturer, needed a visually stunning and user-friendly website. The goal of the project was to develop an online platform that would showcase the brand's products in an engaging and appealing manner, while also facilitating e-commerce transactions. We worked closely with the client to create a website design that was both visually appealing and user-friendly, incorporating a bright and colorful interface that effectively conveyed the brand's message and values. The website was able to effectively highlight the brand's products through high quality visual media and provide an accessible shopping experience for customers.</p>
                                </div>
                                <br/>
                                <br/>
                    <div className="">
                     
                        <div className="">
                            <div className="container">
                                {/* <div className='container'> */}
                           <div className='image-container' >
                            <img id='habari1' src={require('./assets/acai1.PNG')} className="img-fluid mb-2 " alt="CaseStudy"/>   
                            <img id='habari1' src={require('./assets/acai3.PNG')} className="img-fluid mb-2" alt="CaseStudy" />                         
                                <img id='habari1' src={require('./assets/acai2.PNG')} className="img-fluid mb-2" alt="CaseStudy" /> 
                                <img id='habari1' src={require('./assets/acai5.PNG')} className="img-fluid mb-2" alt="CaseStudy" /> 
                                <img id='habari1' src={require('./assets/acai4.PNG')} className="img-fluid mb-2" alt="CaseStudy" />
                                <div id='portfolio-btn' className="col d-flex align-self-center">
                            <Link to='/projects' className='btn btn-outline btn-arrow ms-lg-auto'>
                                 See all projects
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div> 
                                </div>
                                {/* </div> */}
                               <br/>
                            </div>
                        </div>
                    </div>
                </div>
               
            </section>


               
       
        </>
    )
}



const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection bg="bg-shade-blue" contact_data={contact_data}/>
        </>
    )
}


export default Acai
