import './style.scss'
const BrandSection = ({brands,src, light, bordered, bg, pt, customTitle}) => {

    return(
        <section  className="brandclr">
            <div className="container"style={{paddingTop:"10px"}}>
                <div className="row" >
                    <div className="col-12" >
                        <div className={"brand-section "+(pt?'pt-60':'')} >
                            <div className="txt-3 fw-500 text-center mt-4 ">
                                <h4 className='trustedby'>
                                    Trusted by
                                </h4>
                            </div>
                            <div className="brands-container">
                                {
                                    brands.map((brand, index) => 
                                        <div className="brand" key={index}>
                                            <img src={require(`../../pages/${src}/${brand.imgUrl}`)} alt={brand.name} className="img-fluid" />
                                            {/* <img src='https://phpstack-601488-3005830.cloudwaysapps.com/static/media/kia-01.7ce7f85043df6aeefc6d.svg' ></img>
                                            <img src='https://phpstack-601488-3005830.cloudwaysapps.com/static/media/cr%20hope.5006a3c0801b03ff4185.svg' ></img> */}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrandSection