



import Helmet from 'react-helmet';
import { Accordion } from 'react-bootstrap';
import FeatureCards from '../../components/featureCards';
import Footer from '../../components/footer';
import './style.scss';
import NavBar from "../../components/navbar";
import ContactSection from '../../components/contactSection';
import video1 from "./assets/video1.mp4";
import video2 from "./assets/video3.mp4";
import video3 from "./assets/banner_img2.mp4"
import video4 from "./assets/video5.mp4";
import { useState } from 'react';
import Modal from 'react-modal';
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';



const Products = () => {
    return (
        <>
            <Helmet>
                <title>Digitalbuddha</title>
            </Helmet>
            <NavBar />
            <div className="about-container">
                <Hero />
                <Features />
                <Counters />

                <About />

                {/* <Team /> */}
                <FAQSection />
                <Contact />
                <Footer />
            </div>
        </>
    );
};

const Hero = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_fffs5xo",
                "template_icy73ot",
                form.current,
                "WtWnzI12f9avbq-rK"
            )
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Submitted successfully',
                    text: 'Thank you for contacting us!'
                });


            })
            .catch((error) => {
                console.log(error);
            });
    };




    return (
        <>
            <section className=' first_section section-global bg-shade-2 hero'>
                <div>
                    <div className="product_row">
                        {/* <div className="banner_background_img col-lg-24"> */}

                            <div className=" banner_backg_img col-lg-24 align-self-center">
                                
                                <video controls={false} autoPlay muted loop className='banner_video'>
                                    <source src={video3} type="video/mp4" />
                                </video>

                               
                                <div id="open-modal" class="modal-window">

                                    <div>
                                        {/* <div>
                                            <img className='popup_form_img' src="https://images.unsplash.com/photo-1566438480900-0609be27a4be?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80" alt="" />
                                        </div> */}
                                        <a href="#" title="Close" class="modal-close">x</a>
                                        <div class="contact-form">
                                            <form className='popup_form' action="#" method="POST" ref={form} onSubmit={sendEmail}>
                                                <label for="name">Name</label>
                                                <input type="text" id="name" name="name" required />

                                                <label for="name">Phone</label>
                                                <input type="text" id="name" name="number" required />


                                                <label for="email">Email</label>
                                                <input type="text" id="email" name="email" required />

                                                <label for="message">Message</label>
                                                <textarea id="message" name="message" rows="4" required></textarea>

                                                <input type="submit" value="Submit" />
                                            </form>


                                        </div>
                                    </div>

                                </div>
                                <div className="vedio_text ">
                                    <h2>DYNAMIC 3D VISUALIZATION</h2>
                                    <br />
                                    <a className="modal_btn" href="#open-modal">Enquire Now</a>
                                </div>
                                {/* <br /> */}
                                {/* <div className="container">
                                    <div className="interior">
                                    </div>
                                </div> */}
                            </div>


                        {/* </div> */}
                    </div>
                </div>
            </section>
        </>
    );
};

const Counters = () => {
    const counter_data = [
        {
            count: '80%',
            text: "Brands that provide personalized experiences are more likely to attract consumer purchases"

        },
        {
            count: '70%',
            text: "consumers show a preference for interactive 3D models over traditional videos"

        },
        {
            count: '45%',
            text: "Boosts in sales over conventional methods"
        },
    ];
    return (
        <>
            <section className='counter-section2 dark'>
                <div className="container">
                    <div className=" counting_product row">
                        {counter_data.map((e, i) =>
                            <div className=" product_counters col-lg-3 col-md-4 col-6 gy-7 " key={i}>
                                <h2 className="display-1">
                                    {e.count}
                                </h2>
                                <p className='txt-1'>{e.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};



const About = () => {

    const features_data = [
        "Results driven",
        "Quality promised",
        "Cost effective"
    ];

    return (
        <>
            <section id="section" className="section-global bg-shade-1">
                <div className="container">
                    <br /><br />
                    <h1 className='vedio_heading'>3D Product configurators that boost sales</h1>
                    <div className=" single_box1 row mb-40 gy-4">
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{ maxWidth: '1000px' }}>
                                <h2 className='video_h2'>Unlimited Configuration Option</h2>
                                <br />
                                <div className='option_li'>
                                    <li>Customization</li>
                                    <li>Flexibility</li>
                                    <li>Efficiency</li>
                                    <li>Cost-effectiveness</li>
                                    <li>Competitive advantage</li>
                                    <li>Scalability </li>
                                </div>
                            </div>
                        </div>
                        <div className=" single_box1_img1 col-lg-6 align-self-center">
                            <video controls={false} autoPlay muted loop className='vedio1'>
                                <source src={video2} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <br /><br /><br /><br />
                    <div className=" single_box2 row pt-5 gy-4">
                        <div className=" single_box2_img2 col-lg-6 align-self-center">
                            <video controls={false} autoPlay muted loop className='vedio1'>
                                <source src={video1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>                         
                              </div>
                        <div className=" single_box2_content col-lg-6 d-flex justify-content-center align-items-center">
                            <div style={{ maxWidth: '500px' }}>
                                <h2 className='video_h2' style={{ marginTop: "-66px" }}>Ultra-Realistic Product Materials</h2>
                                <br />
                                <div className='option_li'>
                                    <li>Appearance</li>
                                    <li>Functionality</li>
                                    <li>Realism</li>
                                    <li>Enhanced User Experience</li>
                                    <li>Environmental Sustainability</li>
                                    <li>Innovative Design Options</li>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" single_box1 row mb-40 gy-4">
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{ maxWidth: '1000px' }}>
                                <h2 className='video_h2'>Seamless Website Integration</h2>
                                <br />
                                <div className='option_li'>
                                    <li>Consistency</li>
                                    <li>User-Friendly</li>
                                    <li>Brand Identity</li>
                                    <li>Functionality</li>
                                    <li>Mobile Optimization</li>
                                    <li>Third-Party Integration</li>
                                </div>
                            </div>
                        </div>
                        <div className=" single_box1_img1 col-lg-6 align-self-center">
                        <div className=" single_box2_img2 col-lg-6 align-self-center">
                            <video controls={false} autoPlay muted loop className='vedio4'>
                                <source src={video4} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>                         
                              </div>                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};



const Features = () => {

    // const [showContactForm, setShowContactForm] = useState(false);


    // const openContactForm = () => {
    //     setShowContactForm(true);
    // };

    // const closeContactForm = () => {
    //     setShowContactForm(false);
    // };

    return (
        <>
            <section className='product_about section-global'>
                <div className="container">
                    <div className="product-about row mb-40 gy-4">
                        <div className="simplyfy_customer col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{ maxWidth: '1000px' }}>
                                <h3 className='product_h3'>Simplify customer engagement and accelerate sales cycles with minimal effort.</h3>
                                <br />
                                <p className='product_para'>A 3D product configurator is an interactive tool that allows customers to customize and visualize a product in 3D, in real-time. The configurator typically includes a range of options, such as colors, materials, textures, and features that customers can choose from to create a custom product that meets their specific needs.
                                    Product configurators help to enhance customer engagement and satisfaction, allowing them to create and visualize custom products in a way that was not possible before.
                                </p>
                            </div>
                        </div>
                        <div className="product_features col-lg-6 align-self-center">
                            <div className='product_featute_div1'>
                                <img className='product_feature_icon' src={require('./assets/new_icon1.png')} alt="" />
                                <div>
                                    <h5 className='main_h5'>Customer engagement and satisfaction: </h5>
                                    <p className='product_para'>3D product configuration allows customers to personalize and visualize their products in real-time, providing them with a more engaging and satisfying experience.</p>
                                </div>
                            </div>
                            <br />

                            <div className='product_featute_div2'>
                                <img className='product_feature_icon' src={require('./assets/new_icon2.png')} alt="" />
                                <div>
                                    <h5 className='main_h5'>Lower costs and errors in product development: </h5>
                                    <p className='product_para'>3D product configuration enables designers and engineers to quickly create and modify product designs in a virtual environment, reducing the need for physical prototypes and testing.</p>
                                </div>
                            </div>
                            <br />

                            <div className='product_featute_div3'>
                                <img className='product_feature_icon' src={require('./assets/new_icon3.png')} alt="" />
                                <div>
                                    <h5 className='main_h5'>Improved sales and conversions:</h5>
                                    <p className='product_para'>3D product configuration can improve sales and conversions by allowing customers to visualize their products before purchase, resulting in more confident and informed purchasing decisions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button className="contact-btn" onClick={openContactForm}>Contact Us</button> */}
                </div>


                {/* {showContactForm && (
                    <div className="contact-form-popup">
                        <div className="contact-form-content">
                            <span className="close-icon" onClick={closeContactForm}>×</span>
                            <h3>Contact Us</h3>
                            <div class="contact-form">
                                <form className='popup_form' action="#" method="post">
                                    <label for="name">Name:</label>
                                    <input type="text" id="name" name="name" required />

                                    <label for="email">Email:</label>
                                    <input type="text" id="email" name="email" required />

                                    <label for="message">Message:</label>
                                    <textarea id="message" name="message" rows="4" required></textarea>

                                    <input type="submit" value="Submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                )} */}
            </section>
        </>
    );
};

const Team = () => {
    const team_data = [
        {
            name: 'Realistic visualization',
            description: 'With 3D configuration, customers can see the product in a realistic and detailed manner, providing a better understanding of the final product.',
            imgUrl: 'assets/team1.jpg'
        },
        {
            name: 'Dimensions and measurements',
            description: '3D configuration allows customers to view products from all angles and see detailed measurements of each component.',
            imgUrl: 'assets/team2.jpg'
        },
        {
            name: 'Color and texture options',
            description: 'Customers can choose from a wide range of colors and textures to customize the appearance of their product.',
            imgUrl: 'assets/team3.jpg'
        },
        {
            name: 'Material options',
            description: '3D configuration can display different material options, allowing customers to select the most suitable material for their needs.',
            imgUrl: 'assets/team4.jpg'
        },
        {
            name: 'Assembly and disassembly',
            description: ' Customers can see how different components fit together and how the product can be assembled and disassembled.',
            imgUrl: 'assets/team4.jpg'
        },
        {
            name: 'Product personalization',
            description: ' 3D configuration enables customers to personalize their products according to their preferences, such as adding their names, logos, or other custom designs.',
            imgUrl: 'assets/team4.jpg'
        }

    ];
    return (
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-lg-8">
                            <h2 className=' section4_h2 mb-16'>A comprehensive range of options for customizing products</h2>
                            <p className='grid_para'>At every stage of customer interaction, our product configurator is equipped with a wide range of features that are specifically designed to enhance the overall customer experience</p>


                        </div>
                        <br /><br /><br /> <br /><br /><br />
                    </div>
                    <div className=" grid-section row gy-4">
                        {team_data.map((e, i) =>
                            <div className="grid-section-images col-lg-3 col-md-4 col-sm-6" key={i}>
                                <div className="team-member text-center">
                                    <img src={require(`./${e.imgUrl}`)} className="img-fluid mb-16" alt={e.name} />
                                    <div className="txt-2 color-1 fw-500">{e.name}</div>
                                    <div className=" grid_description txt-3">{e.description}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>




            </section>
        </>
    );
};








const FAQSection = () => {


    const FAQ_data = [
        {
            title: 'How does a 3D configurator work?',
            text: 'A 3D configurator uses computer-generated graphics and imaging to create a realistic visualisation of a product. Customers can interact with the configurator by selecting different options or features to customise their product.'
        },
        {
            title: 'What are the benefits of using a 3D product configurator for my business?',
            text: 'A 3D configurator can help businesses increase customer engagement, improve customer satisfaction, and reduce the number of product returns. It also allows for more efficient production, as the configured product can be sent directly to the manufacturer for production.'
        },
        {
            title: 'Can a 3D product configurator integrate with my existing e-commerce platform?',
            text: 'Yes, most 3D product configurators can be integrated with existing e-commerce platforms such as Shopify, Magento, WooCommerce, and BigCommerce.'
        },
        {
            title: 'Can a 3D product configurator be used for marketing purposes?',
            text: 'Yes, a 3D product configurator can be used as a marketing tool to showcase the various customisation options and features of a product. It can also help to attract new customers and increase brand awareness.'
        },
        {
            title: 'What industries can benefit from using a 3D product configurator?',
            text: ' Any industry that offers customisable products can benefit from using a 3D product configurator. Some examples include furniture, fashion, automotive, electronics, and home appliances.'
        },
        {
            title: 'Do I need special software to use a 3D product configurator?',
            text: ' Most 3D product configurators are web-based and do not require any special software or downloads. Customers can access the configurator through a web browser on their desktop or mobile device.'
        },
        {
            title: 'Can a 3D product configurator be integrated with my existing website?',
            text: ' Yes, most 3D product configurators can be integrated with existing websites. The level of integration can vary depending on the platform used for the configurator.'
        },
        {
            title: 'How does a 3D product configurator improve customer experience?',
            text: ' A 3D product configurator enhances the customer experience by allowing them to visualise and personalise the product, making it easier for them to make a purchase decision.'
        },
        {
            title: 'Can a 3D product configurator be used for virtual try-ons?',
            text: 'Yes, 3D product configurators can be used for virtual try-ons, particularly in the fashion and beauty industries. Customers can see how different clothing or makeup options look on a virtual model.'
        },
        {
            title: 'What file formats can be used with a 3D product configurator? ',
            text: 'The file formats that can be used with a 3D product configurator can vary depending on the specific software or platform being used. '
        },

    ];

    return (
        <>
            <section className='faq-section section-global'>
                <div className="container container-2">
                    <h2 className='faqs'>FAQs</h2>
                    <br /><br /><br /><br />
                    <div className="row">
                        <div className="col-12">
                            <Accordion alwaysOpen flush>
                                {FAQ_data.map((e, i) =>
                                    <Accordion.Item eventKey={i} key={i}>
                                        <Accordion.Header>{e.title}</Accordion.Header>
                                        <Accordion.Body>
                                            {e.text}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};




const Contact = () => {

    const contact_data = {
        title: 'Have an idea?',
        title_highlight: "Let's talk",
        text: "One of the best industry service providers with top tier talented individuals.",
        link: '/contact'
    };

    return (
        <>
            <ContactSection contact_data={contact_data} />
        </>
    );
};





export default Products;